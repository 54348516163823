.partners__page {
  width: 100%;
  .partners {
    width: 100%;
    padding-top: 65px;
    .cnt {
      overflow: visible; }
    &__logo {
      height: 90px;
      width: auto;
      object-fit: contain; }
    &__name {
      font-size: 36px;
      line-height: 42px;
      color: #000000;
      margin-top: 43px;
      @include for-phone {
        font-size: 24px;
        line-height: 30px; } }
    &__desc {
      margin-top: 32px;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
      width: 100%;
      max-width: 650px;
      a {
        color: inherit;
        text-decoration: none; }
      @include for-phone {
        font-size: 14px;
        line-height: 20px; } }
    &__video {
      margin-top: 49px;
      border-radius: 20px;
      width: 100%;
      height: 665px;
      @include for-phone {
        height: 184px;
        margin-top: 35px; } }
    &__about {
      margin-top: 42px;
      @include for-phone {
        margin-top: 30px; }
      .head {
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        @include for-phone {
          font-size: 20px;
          line-height: 23px; } } }
    &__text {
      margin-top: 45px;
      font-size: 24px;
      line-height: 36px;
      color: rgba(#000, 1);
      @include for-phone {
        font-size: 14px;
        line-height: 24px;
        margin-top: 11px; } }
    &__contact {
      width: 100%;
      margin-top: 68px;
      border-top: 1px solid rgba(#000, .2);
      .cnt {
        padding-top: 35px;
        overflow: hidden; }
      .contact {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        top: 1000px;
        @include for-phone {
          flex-direction: column;
          justify-content: center;
          align-items: center; }
        &__head {
          font-size: 24px;
          line-height: 28px;
          color: #000000;
          margin-bottom: 35px;
          @include for-phone {
            text-align: center; } }
        &__el {
          height: 187px;
          width: 187px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-right: 20px;
          cursor: pointer;
          background-color: inherit;
          position: relative;
          overflow: visible;
          color: inherit;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 14px; }
          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: -1000px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: inherit;
            opacity: .4;
            border-radius: 20px; }

          .logo {
            height: 60px;
            width: 60px;
            border-radius: 60%;
            background: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 14px;
            transition: .5s;
            position: relative;
            z-index: 2;
            top: -1000px; }
          .name {
            font-size: 15px;
            line-height: 17px;
            text-align: center;
            color: #000000;
            transition: .5s;
            position: relative;
            z-index: 2;
            top: -1000px; }
          &:hover {
            .logo {
              background: inherit; }
            .name {
              color: inherit; } } } } }


    .gallery {
      margin-top: 50px;
      padding-top: 45px;
      padding-bottom: 75px;
      position: relative;
      @include for-phone {
        margin-top: 34px;
        padding-top: 34px;
        padding-bottom: 25px; }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        transform: translate(-50%, -50%);
        height: 1px;
        background: rgba(#000, .1); }
      &__head {
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 44px;
        @include for-phone {
          font-size: 20px;
          line-height: 23px;
          margin-bottom: 19px; } }

      &__el {
        width: 33%;
        height: 324px;
        padding: 10px;
        transition: .5s;
        @include for-phone {
          height: 166px; }
        &.show {
          position: fixed;
          top: 0;
          left: 0;
          height: 600px !important;
          width: 600px !important; }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 20px; } } }

    .slick-list {
      overflow: visible; }
    .slick-slide {
      opacity: .5;
      transition: .5s; }
    .slick-active {
      opacity: 1; }
    .slick-arrow {
      position: absolute;
      top: -60px;
      left: 140px;
      width: 69px;
      height: 30px;
      outline: 0 none;
      background-color: transparent;
      color: transparent;
      border: 0 none;
      @include for-phone {
        display: none !important; }
      &:before {
        display: none; }
      &.slick-prev {
        background-image: url(../../image/arrowPrev.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
      &.slick-next {
        left: 230px;
        background-image: url(../../image/arrowNext.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; } } } }


.vmeste {
  .partners__flex {
    flex-direction: column!important; }
  .partner_el {
    width: 100% !important;
    flex-direction: row!important;
    justify-content: space-between!important;
    align-items: center!important;
    display: flex!important;

    background: #F5F5F5;
    border-radius: 12px;
    padding: 20px 40px;
    @include for-phone {
      padding: 15px; }
    .name {
      margin-top: 0;
      text-align: left; }
    .icon {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #DEDEDE;
      position: relative;
      cursor: pointer;
      flex-shrink: 0;
      @include for-phone {
        width: 30px;
        height: 30px; }
      &:hover {
        background: #D7D7D7; }
      &:before,
      &:after {
        content: '';
        height: 2px;
        width: 36px;
        background: #FF6700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include for-phone {
          width: 20px; } }
      &:after {
        transform: translate(-50%, -50%) rotate(90deg); }
      &.minus {
        &:after {
          display: none; } } } } }




