.activitySlider {
  width: 100%;
  margin-top: 59px;
  overflow: hidden;
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-text {
      font-size: 48px;
      line-height: 56px;
      color: #000000;
      margin-right: 56px;
      @include for-phone {
        font-size: 24px;
        line-height: 2px;
        margin-right: 0; } }
    &-arrows {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .arrow {
        margin-right: 21px;
        cursor: pointer;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        path {
          transition: .5s; }
        &:last-child {
          margin-right: 0; }
        &:hover {
          path {
            fill: #FF6700; } } } } }


  &__slider {
    margin-top: 40px;
    margin-bottom: 40px;
    &-el {
      padding-left: 10px;
      padding-right: 10px; }
    .slick-list {
      overflow: visible; }
    .slick-slide {
      opacity: .5;
      transition: .5s; }
    .slick-active {
      opacity: 1; }
    .slick-arrow {
      position: absolute;
      top: -65px;
      left: 340px;
      width: 69px;
      height: 30px;
      outline: 0 none;
      background-color: transparent;
      color: transparent;
      border: 0 none;
      &:before {
        display: none; }
      &.slick-prev {
        background-image: url(../../../image/arrowPrev.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
      &.slick-next {
        left: 430px;
        background-image: url(../../../image/arrowNext.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; } } } }









