.step {
  min-height: calc(100vh - 400px - 90px);
  padding: 50px 0;
  &__head {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #FF6700; }
  &__text {
    margin-top: 24px;
    font-size: 18px;
    line-height: 30px;
    color: #000; }
  &__video {
    margin-top: 24px;
    width: 100%;
    height: auto; }
  iframe.step__video {
    min-height: 1124px;
    @include for-phone {
      min-height: calc(100vh - 200px); } }
  &__btn {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }

  .matches {
    margin-bottom: 20px;
    &__in {
      margin-top: 15px;
      margin-bottom: 10px; }
    &__out {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 5px;
      label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 15px;
        margin-bottom: 10px; }
      input {
        display: none; }
      .checkBox {
        display: inline-block;
        height: 20px;
        width: 20px;
        box-shadow: 0 0 0 1px #FF6700;
        border: 2px solid #fff;
        margin-right: 5px;
        background: #fff; }
      input:checked + .checkBox {
        background: #FF6700; } } }

  &__textArea {
    margin-top: 50px;
    width: 100%;
    height: 200px;
    resize: none;
    border: 1px solid #FF6700; }
  &__file {
    position: relative;
    width: auto;
    display: inline-block;
    cursor: pointer;
    input {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
    .button {} } }



