.registration {
    width: 100%;
    padding-top: 45px;
    padding-bottom: 73px;
    @include for-phone {
        padding-top: 25px;
        padding-bottom: 25px; }
    .cnt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include for-phone {
            flex-direction: column;
            justify-content: center; } }

    &__text {
        margin-left: 23px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        opacity: 0.5;
        @include for-phone {
            margin-left: 0;
            margin-top: 15px; } } }
