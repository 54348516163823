.forumBanner {
  width: 100%;
  &__link {
    display: block; }
  &__img {
    width: 100%;
    height: auto;
    margin-bottom: 19px; }
  &__foot {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include for-phone {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  &__date {
    font-size: 24px;
    line-height: 28px;
    color: #FF6700;
    margin-bottom: 12px;
    @include for-phone {
      font-size: 19px;
      line-height: 22px; } }

  &__text {
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    @include for-phone {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 15px; } } }
