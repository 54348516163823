.button {
  outline: 0 none;
  border: 0 none;
  cursor: pointer;
  display: inline-block;
  &__yellow {
    background: #FFD93A;
    border-radius: 20px;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
    padding: 20px 40px;
    @include for-phone {
      display: inline-block;
      padding: 20px 0;
      text-align: center;
      width: 100%; }
    &:hover {
      background: #D73527; } }
  &__white {
    background: #fff;
    border-radius: 20px;
    font-size: 15px;
    line-height: 17px;
    color: #D73527;
    border: 1px solid #D73527;
    padding: 20px 40px;
    @include for-phone {
      display: inline-block;
      padding: 20px 0;
      text-align: center;
      width: 100%; }
    &:hover {
      color: #fff;
      background: #D73527; } }
  &__orange {
    background: #D73527;
    border-radius: 20px;
    font-size: 15px;
    line-height: 17px;
    color: #fff;
    padding: 20px 40px;
    @include for-phone {
      display: inline-block;
      padding: 20px 0;
      text-align: center;
      width: 100%; }
    &:hover {
      background: #D73527; } }
  &__big {
    padding: 20px 150px;
    @include for-phone {
      display: inline-block;
      padding: 20px 0;
      width: 100%;
      text-align: center; } }
  &__link {
    background: transparent;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: rgba(#000, 0.4);
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #D73527; } } }


