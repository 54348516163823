.opportunities {
  width: 100%;
  &__head {
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    padding-top: 45px;
    padding-bottom: 34px;
    border-top: 1px solid rgba(#000, .1);
    border-bottom: 1px solid rgba(#000, .1);
    @include for-phone {
      padding-top: 17px;
      padding-bottom: 25px;
      font-size: 24px;
      line-height: 28px;
      text-align: center; } }
  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 16px;
    @include for-phone {
      flex-direction: column; }
    .flex__el {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 48px;
      @include for-phone {
        width: 100%; }
      &-img {
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #FF6700;
        margin-bottom: 20px; }
      &-text {
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        text-align: center; } } } }
