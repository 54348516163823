.popap {
  position: fixed;
  z-index: 334;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrp {
    padding: 50px;
    background: #ffffff;
    border-radius: 10px;
    position: relative; }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 20px;
    width: 20px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 1px;
      background: #000; }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg); } } }
