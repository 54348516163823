.userData {
  width: 100%;
  min-height: 20px;
  background: #F3F3F3;
  padding: 40px 0 46px;
  .info {
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(#000, .1);
    .cnt {
      .name {
        font-size: 36px;
        line-height: 30px;
        color: #000000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include for-phone {
          flex-direction: row-reverse;
          font-size: 23px; }

        span {
          display: inline-block;
          margin-left: 20px;
          font-size: 18px;
          color: rgba(#000, .5);
          cursor: pointer;
          transition: .5s;
          position: relative;
          top: -4px;
          text-decoration: underline;
          &:hover {
            color: #FF6700; }
          @include for-phone {
            margin-left: 0; } } }
      .level {
        margin-top: 32px;
        width: 100%;
        max-width: 400px;
        &__text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 15px;
          line-height: 24px;
          color: rgba(#000000, .5); }
        &__line {
          margin-top: 14px;
          width: 100%;
          height: 5px;
          border-radius: 40px;
          background: #DFDFDF;
          &-done {
            height: 100%;
            background: #FF6700;
            border-radius: 40px; } } }
      .ava {
        height: 187px;
        width: 187px;
        border-radius: 187px;
        overflow: hidden;
        transition: .5s;
        cursor: pointer;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          height: 100%;
          width: 100%;
          background-image: url(../../../image/camera.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-color: rgba(#000, .4);
          opacity: 0;
          transition: .5s; }
        @include for-phone {
          height: 68px;
          width: 68px;
          margin-right: 18px; }
        &:hover {
          &:before {
            opacity: 1; } }
        .ava__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .fileUploader {
          position: absolute;
          z-index: 3;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: 100%; }

        .fileUploader,
        .fileContainer {
          height: 100%;
          padding: 0;
          margin: 0;
          background: transparent; }
        .chooseFileButton {
          padding: 0;
          margin: 0;
          color: transparent;
          background: transparent;
          height: 100%;
          width: 100%; } } } }


  .events {
    padding-top: 25px;
    &__head {
      font-size: 18px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 20px; }
    &__flex {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      &-el {
        width: 187px;
        height: 140px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 10px 15px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-right: 20px;
        transition: .5s;
        cursor: pointer;
        color: #ffffff;
        &:last-child {
          margin-right: 0; }
        .name {
          font-size: 12px;
          line-height: 18px;
          color: #000000;
          transition: .5s; }
        .count {
          font-size: 36px;
          line-height: 24px;
          color: #000000;
          transition: .5s; }
        &:hover {
          background: #FF6700;
          .name,
          .count {
            color: #fff; } } } } } }

