.timeTable {
  width: 100%;
  &__head {
    margin-top: 114px;
    font-size: 36px;
    line-height: 42px;
    color: #000000; }
  &__days {
    .day {
      &__el {
        margin-top: 43px; }
      &__head {
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        color: #FF6700; }
      &__name {
        margin-top: 11px;
        font-size: 36px;
        line-height: 42px;
        color: #000000; }
      &__list {
        margin-top: 20px;
        @import "src/assets/sass/pageComponents/programs/list-el"; } } } }




