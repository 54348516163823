&__list {
  margin-top: 47px;
  @include for-phone {
    margin-top: 36px; }
  &-tz {
    font-size: 22px;
    color: #000;
    width: 100%;
    @include for-phone {
      font-size: 14px; } }
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 47px;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
    @include for-phone {
      flex-direction: column;
      align-items: flex-start;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 36px; } }

  &-date {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include for-phone {
      width: 100%;
      justify-content: space-between; } }

  &-arrow {
    margin-left: 50px;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid #d53827;
    border-right: 3px solid #d53827;
    transform: rotate(45deg);
    cursor: pointer;
    transition: .5s;
    @include for-phone {
      margin-left: 30px; }
    &.active {
      transform: translate(0, 15px) rotate(-135deg); } }


  &-track {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include for-phone {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; }
    .track__el {
      font-size: 18px;
      line-height: 18px;
      margin-left: 53px;
      text-transform: none;
      position: relative;
      border-left: 4px solid;
      padding-left: 7px;
      @include for-phone {
        margin-left: 0;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 1; }
      &:first-child {
        margin-left: 0; }
      &:before {
        content: ''; }
      &-red {
        color: #ff0000;
        border-color: #ff0000; }
      &-blue {
        color: #5222D0;
        border-color: #5222D0; }
      &-green {
        color: #0099AA;
        border-color: #0099AA; } } } }

&__alt-list {
  margin-top: 47px;
  @include for-phone {
    margin-top: 36px; }
  &-tz {
    font-size: 22px;
    color: #000;
    width: 100%;
    @include for-phone {
      font-size: 14px; } }
  &-head {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 47px;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
    @include for-phone {
      flex-direction: column;
      align-items: flex-start;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 36px; } }

  &-date {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include for-phone {
      width: 100%;
      justify-content: space-between; }
    &>.left {
      display: flex;
      flex-direction: row; } }

  &-arrow {
    margin-left: 20px;
    width: 20px;
    height: 20px;
    border-bottom: 8px solid #d53827;
    border-right: 8px solid #d53827;
    transform: rotate(45deg);
    cursor: pointer;
    transition: .5s;
    @include for-phone {
      margin-left: 30px; }
    &.active {
      transform: translate(0, 10px) rotate(-135deg); } }


  &-track {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include for-phone {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; }
    .track__el {
      font-size: 18px;
      line-height: 18px;
      margin-left: 53px;
      text-transform: none;
      position: relative;
      border-left: 4px solid;
      padding-left: 7px;
      @include for-phone {
        margin-left: 0;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 1; }
      &:first-child {
        margin-left: 0; }
      &:before {
        content: ''; }
      &-red {
        color: #ff0000;
        border-color: #ff0000; }
      &-blue {
        color: #5222D0;
        border-color: #5222D0; }
      &-green {
        color: #0099AA;
        border-color: #0099AA; } } } }

