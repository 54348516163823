.main__page {
  @import "../pageComponents/banner/index";
  @import "../pageComponents/registration/index";
  @import "../pageComponents/opportunities/index";
  @import "../pageComponents/forumBanner/index";
  @import "../pageComponents/activitySlider/index";

  .partners {
    &__flex {
      .partner_el:last-child {
        visibility: hidden; } } } }
