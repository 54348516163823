.login {
  &__container {
    max-width: 768px;
    border-radius: 48px;
    background: #cc0001;
    margin: 50% auto 0;
    display: flex;
    flex-direction: column;
    transform: translate(0, -50%);
    &>div {
      padding: 48px;
      background: url("../../image/login_bg.svg") right top no-repeat;
      background-size: 50% 50%;
      @include for-phone {
        padding: 24px; } }
    @include for-phone {
      transform: translate(0, -20%);
      border-radius: 24px; } }
  &__row {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include for-phone {
      width: 100%; } }
  &__title {
    font-size: 40px;
    line-height: 42px;
    color: #FFF;
    margin: 50px 0 10px;
    @include for-phone {
      font-size: 32px;
      line-height: 36px; } }
  &__text {
    font-size: 20px;
    line-height: 24px;
    color: #FFF;
    margin: 0 0 20px;
    @include for-phone {
      font-size: 16px;
      line-height: 18px; } }
  &__frame {
    width: 100%;
    height: 130px;
    background: #FFF;
    border-radius: 20px;
    & iframe {
      border-radius: 20px; } }
  &__image {
    width: 30%; }
  &__header {
    width: 60%;
    font-size: 24px;
    line-height: 30px;
    color: #FFF;
    @include for-phone {
      font-size: 20px;
      line-height: 24px; } } }
