.project__page {
  width: 100%;
  .project {
    width: 100%;
    margin-top: 40px;
    .cnt {
      overflow: visible; }

    &__header {
      background: #fff;
      padding: 45px 0 23px; }
    &__name {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      color: #D53827;
      width: 100%;
      max-width: 840px;
      @include for-phone {
        font-size: 24px;
        line-height: 30px; } }
    &__desc {
      margin-top: 34px;
      font-size: 18px;
      line-height: 30px;
      color: #656565;
      width: 100%;
      max-width: 840px;
      .flex {
        margin-top: 40px;
        display: flex;
        align-items: flex-start;
        &-between {
          justify-content: space-between; }
        &-start {
          justify-content: flex-start; }
        &-end {
          justify-content: flex-end; }
        span {
          font-size: 20px;
          line-height: 23px;
          color: #D53827; } } }

    @include for-phone {
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px; }
    &__wrp {
      padding-top: 40px;
      @include for-phone {
        padding-top: 20px; } }
    .result {
      &__head {
        font-size: 48px;
        line-height: 56px;
        color: #000000;
        margin-bottom: 35px;
        @include for-phone {
          font-size: 24px;
          line-height: 30px;
          margin-top: 20px; } }
      &__text {
        font-size: 18px;
        line-height: 30px;
        color: rgba(#000000, .5);
        width: 100%;
        max-width: 840px;
        @include for-phone {
          font-size: 14px;
          line-height: 20px; } } }
    .contact {
      margin-top: 70px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      @include for-phone {
        margin-top: 35px; }
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #000000;
        margin-right: 20px;
        margin-bottom: 20px;
        @include for-phone {
          font-size: 14px;
          line-height: 20px; }
        svg {
          margin-right: 15px; } } }





    .gallery {
      padding-bottom: 54px;
      @include for-phone {
        padding-bottom: 25px; }
      &__head {
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 44px;
        @include for-phone {
          font-size: 20px;
          line-height: 23px;
          margin-bottom: 19px; } }

      &__el {
        width: 33%;
        height: 324px;
        padding: 10px;
        transition: .5s;
        @include for-phone {
          height: 166px; }
        &.show {
          position: fixed;
          top: 0;
          left: 0;
          height: 600px !important;
          width: 600px !important; }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 20px; } } }

    .slick-list {
      overflow: visible; }
    .slick-slide {
      opacity: .5;
      transition: .5s; }
    .slick-active {
      opacity: 1; }
    .slick-arrow {
      position: absolute;
      top: -60px;
      left: 140px;
      width: 69px;
      height: 30px;
      outline: 0 none;
      background-color: transparent;
      color: transparent;
      border: 0 none;
      @include for-phone {
        display: none !important; }
      &:before {
        display: none; }
      &.slick-prev {
        background-image: url(../../image/arrowPrev.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
      &.slick-next {
        left: 230px;
        background-image: url(../../image/arrowNext.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; } } } }



