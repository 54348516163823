.headerVmeste {
  padding: 20px;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include for-phone {
    flex-wrap: wrap; }
  &.bg {
    background: linear-gradient(269.97deg, #FFD939 -5.75%, #FF6700 97.42%);
    position: relative; }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    width: calc(100% - 40px);
    height: 1px;
    background: rgba(#fff, .3); }
  .cnt {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 49px;
    border: 2px solid #fff;
    padding: 0 15px;
    border-radius: 50px;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    flex-shrink: 0;
    @include for-phone {
      font-size: 20px;
      padding: 0 10px;
      height: auto; } }
  .partners {
    margin-left: 148px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @include for-phone {
      order: 3;
      margin-left: 0;
      margin-top: 10px;
      transform: scale(.7);
      transform-origin: top left; }


    &_el {
      &:not(:last-child) {
        margin-right: 50px; } } }
  .special {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    white-space: nowrap;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none; }
    &__icon {
      height: 38px;
      width: 38px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 14px;
      flex-shrink: 0; } }
  .login {
    margin-left: 36px;
    background: #FF6700;
    border-radius: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 18px;
    position: relative;
    @include for-phone {
      order: 2;
      margin-left: 20px;
      font-size: 14px;
      position: absolute;
      top: 20px;
      right: 20px; }
    .click {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      padding: 10px 35px 10px 15px;
      @include for-phone {
        padding: 5px 17px 5px 7px; } }

    svg {
      margin-right: 15px;
      @include for-phone {
        margin-right: 7px;
        width: 20px;
        height: 20px; } }
    .down {
      display: none;
      position: absolute;
      z-index: 33;
      bottom: -20px;
      right: 0;
      transform: translate(0, 100%);
      //display: flex
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      padding: 16px;
      &.open {
        display: flex; }
      &__el {
        white-space: nowrap;
        color: #767676;
        font-size: 14px;
        &:not(:last-child) {
          margin-bottom: 20px; }
        &:hover {
          color: #FF6700; } } } }
  .lang {
    position: absolute;
    z-index: 10;
    right: 20px;
    bottom: -20px;
    transform: translate(0, 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 50px;
    @include for-phone {
      right: 20px;
      bottom: -10px;
      transform: translate(0); }
    &__el {
      font-size: 14px;
      line-height: 19px;
      padding: 17px;
      color: #000;
      cursor: pointer;
      @include for-phone {
        font-size: 10px;
        line-height: 12px;
        padding: 10px; }
      &-active {
        padding: 17px 30px;
        background: #FF6700;
        color: #fff;
        border-radius: 50px;
        @include for-phone {
          padding: 10px 15px; } } } } }

