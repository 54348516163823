.forums__page {
  .forums {
    &__all {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 35px;
      .forums__el {
        width: calc(33% - 40px / 3 * 2);
        margin-right: 20px;
        margin-bottom: 60px;
        @include for-phone {
          width: 100%;
          margin-right: 0; }
        .forumsEl__img {
          border-radius: 20px;
          overflow: hidden; }
        &:nth-child(3n) {
          margin-right: 0; } } } }

  .projects__all,
  .partners__all,
  .organizer_all {
    .partners__head {
      margin-top: 50px; }
    .partner_el {
      width: calc(33% - (20px / 3 * 2));
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      display: none;
      @include for-phone {
        width: 100%; }
      &:nth-child(3n) {
        margin-right: 0; }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: flex; } }
    &.show {
      .partner_el {
        display: flex; } }
    .partners__btns {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 42px;
      margin-bottom: 50px; } }

  .projects__all {
    .partner_el-icon {
      width: 100%;
      height: 324px;
      border-radius: 20px; }
    .partner_el-img {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      img {
        position: static;
        transform: translate(0);
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
        object-position: center; } } }

  .partners__all {
    .partner_el {
      &:last-child {
        visibility: hidden; } } }
  .organizer_all {
    .partner_el {
      width: calc(50% - 10px);
      margin-right: 20px;
      display: flex;
      &:nth-child(2n) {
        margin-right: 0; }
      &:nth-child(3n) {
        margin-right: 20px; }
      &:nth-child(4) {
        display: flex; } } } }
