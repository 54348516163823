.swiper {
  width: 100%;
  height: 100%; }


.slider__element {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../image/slider_bg.svg") center top no-repeat;
  background-size: contain;
  padding: 60px 10px;
  height: 465px;
  @include for-1000 {
    padding: 30px 10px;
    background-size: cover;
    background-position: 78%; }
  @include for-phone {
    height: 550px;
    flex-direction: column;
    background: #cc0001; } }
.slider__element img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.slider {
  width: 100%;
  background-color: #cc0001;
  &__left-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include for-1000 {
      width: 100%; } }
  &__right-side {
    width: 50%;
    height: 100%;
    padding-right: 30px;
    @include for-1000 {
      width: 100%;
      padding-right: 0;
      margin-top: 20px; }
    @include for-phone {
      height: 50%;
      & svg {
        height: 80%; } } }
  &__title {
    font-size: 46px;
    line-height: 60px;
    color: #FFF;
    text-align: left;
    margin: 0 0 20px;
    @include for-1000 {
      font-size: 30px;
      line-height: 34px; }
    @include for-phone {
      text-align: center; } }
  &__city {
    font-size: 22px;
    line-height: 24px;
    color: #FFF;
    text-align: left;
    margin: 0 0 10px;
    @include for-1000 {
      font-size: 16px;
      line-height: 18px; }
    @include for-phone {
      text-align: center; } }
  &__date {
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    color: #FFF;
    text-align: left;
    margin: 0;
    @include for-1000 {
      font-size: 16px;
      line-height: 18px; }
    @include for-phone {
      text-align: center; } }
  &__link {
    margin: 20px 0 0;
    width: 200px;
    font-size: 20px;
    line-height: 22px;
    padding: 10px;
    border-radius: 20px;
    background-color: #FFF;
    color: #cc0001;
    @include for-phone {
      margin: 20px auto 0; } } }
.swiper-pagination-bullet {
  background-color: #cc0001;
  border: 1px solid #FFF;
  opacity: 0.5;
  width: 10px;
  height: 10px; }
.swiper-pagination-bullet-active {
  background-color: #FFF;
  opacity: 1; }
