.footer {
  width: 100%;
  //padding-top: 93px
  margin-top: 110px;
  padding-bottom: 35px;
  padding-top: 35px;
  background: #D73527;
  &.footer__ed {
    background: #CB0001; }
  .cnt {
    overflow: visible; }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    //&:last-child
    //  margin-top: 238px
    //@include for-phone
    //  margin-top: 47px
    //  flex-direction: column
    //  justify-content: center
    //  align-items: center
    &-el {
      font-size: 15px;
      line-height: 17px;
      color: #FFFFFF;
      transition: .5s;
      &:not(:last-child) {
        margin-right: 5px; }
      &:nth-of-type(1) {
        width: 20%;
        @include for-1000 {
          width: 100%;
          display: flex;
          justify-content: center;
          & svg {
            width: 50%;
            margin-bottom: 20px; } } }
      &:nth-of-type(2) {
        width: 35%;
        @include for-1000 {
          width: 100%; } }
      &:nth-of-type(3) {
        width: 40%;
        @include for-1000 {
          width: 100%; } }
      &-image {
        width: 100%; }
      @include for-phone {
        margin-bottom: 15px;
        font-size: 12px;
        line-height: 14px;
        &:last-child {
          margin-bottom: 0; } }
      &.onTop {
        cursor: pointer;
        transition: .5s;
        &:hover {
          opacity: 0.7; } } }
    a {
      &:hover {
        opacity: 0.7; } }
    .social {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      &__el {
        margin: 0 17px;
        path {
          transition: .5s;
          opacity: 1; }
        &:hover {
          path {
            opacity: 1; } }
        //&-vk
        //  &:hover
        //    path
        //      opacity: 1
        //      fill: #4c75a3
        //&-fb
        //  &:hover
        //    path
        //      opacity: 1
        //      fill: #3b5999
        //&-insta
        //  &:hover
        //    path
        //      opacity: 1
 } } }        //      fill: #e4405f


  .navigation__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    @include for-phone {
      flex-direction: column;
      align-items: center; }
    &-el {
      font-size: 18px;
      line-height: 21px;
      color: #FFF;
      margin-right: 68px;
      margin-bottom: 15px;
      border-bottom: 1px solid transparent;
      @include for-phone {
        margin-right: 0;
        margin-bottom: 20px; }

      &:last-child {
        margin-right: 0; }
      &:hover {
        border-bottom: 1px solid #FFF; } } }


  &.fVmeste {
    .footer__row:first-child {
      display: none; }
    .footer__row:last-child {
      margin-top: 0; } }
  &__logo {
    position: absolute;
    left: 43%;
    top: 0;
    transform: translate(0, -80%);
    @include for-1000 {
      width: 200px;
      height: auto;
      transform: translate(0, -100%); }
    @media screen and (max-width: 500px) {
      left: 35%; } }
  .cls-2 {
    fill: #FFF; }
  .cls-1 {
    fill: #cc0001; }
  .header__account-link {
    flex-direction: row-reverse;
    @include for-1000 {
      justify-content: center;
      & .header__account-text {
        display: block; } }
    & span {
      color: #FFF; } }
  .header__account-logo {
    width: 40px;
    height: 30px;
    margin-right: 10px; }
  &__subscribe-el {
    margin-top: 50px; }
  &__subscribe {
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    @include for-1000 {
      text-align: center; } } }
