.targets {
  position: relative;
  width: 100%;
  margin: 0;
  //padding-bottom: 110px
  @include for-phone {
    margin-bottom: 40px; }
  &__header {
    background-color: #f6f6f6; }
  &__title {
    font-size: 28px;
    line-height: 32px;
    padding: 30px 20px 50px;
    color: #000;
    //margin-bottom: 48px
    background-color: #f6f6f6;
    //text-transform: uppercase
    text-align: center;
    & p {
      margin: 0; }
    @include for-1000 {
      font-size: 20px;
      line-height: 30px; }
    @include for-phone {
      font-size: 18px;
      line-height: 26px; } }
  &__line {
    width: 230px;
    height: 5px;
    background-color: #cc0001;
    margin: 5px auto 20px; }
  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #303030;
    text-align: center;
    margin-bottom: 48px;
    padding: 0 60px;
    @include for-1000 {
      padding: 0;
      font-size: 14px;
      line-height: 16px; } }
  &__list-title {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    color: #303030;
    //margin-bottom: 48px
    & p {
      margin: 0; }
    @include for-1000 {
      font-size: 20px;
      line-height: 30px; }
    @include for-phone {
      font-size: 18px;
      line-height: 26px; } }
  &__list {
    list-style: none;
    max-width: 666px;
    margin: 0 auto;
    padding: 0; }
  &__item {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 27px;
    color: #303030;
    @include for-1000 {
      font-size: 16px;
      line-height: 26px; }
    @include for-phone {
      font-size: 14px;
      line-height: 18px; }
    &:not(:last-child) {
      margin-bottom: 20px; } }
  &__number {
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
    color: #CB0001;
    border: 5px solid #cc0001;
    border-radius: 50%;
    padding: 10px 0; }
  &__text {
    width: calc(100% - 92px);
    font-size: 20px;
    line-height: 27px;
    color: #303030;
    @include for-1000 {
      font-size: 16px;
      line-height: 26px; }
    @include for-phone {
      font-size: 14px;
      line-height: 18px; } }
  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 70px;
    padding: 0 100px;
    @include for-1000 {
      padding: 0;
      flex-direction: column;
      margin-top: 30px; } }
  &__item-block {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 80px;
    @include for-1000 {
      flex-direction: row;
      margin-bottom: 30px;
      width: 100%;
      align-items: flex-start; } }
  &__left-side {
    width: 35%;
    @include for-1000 {
      width: 35%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px; }
    & svg {
      width: 80%;
      @include for-1000 {
        width: 25%; }
      @include for-phone {
        width: 80%; } } }
  &__right-side {
    width: 55%;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    @include for-1000 {
      width: 60%;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      text-align: center; } }
  &__body {
    background: url("../../../image/white_bg.svg") center top no-repeat;
    background-size: cover;
    padding: 30px 0 30px; } }
