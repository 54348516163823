.list_el {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  &.show {
    display: flex; }
  &.hide {
    display: none; }

  @include for-phone {
    flex-wrap: wrap; }
  &:last-child {
    margin-bottom: 0; }
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    flex-shrink: 0;
    @include for-phone {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 7px; } }
  .time {
    width: 110px;
    font-size: 18px;
    color: #000000;
    flex-shrink: 0;
    margin-right: 27px;
    white-space: nowrap;
    @include for-phone {
      font-size: 13px;
      line-height: 14px; } }
  .speakers {
    width: 110px;
    font-size: 14px;
    color: #767676;
    @include for-phone {
      width: 100%;
      white-space: pre-line; } }
  .element {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 23px;
    background: #D5D5D5;
    border: 1px solid #D5D5D5;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    &.white {
      background: #FFF; }
    @include for-phone {
      padding: 8px 13px; }
    .name {
      font-size: 18px;
      color: #000;
      width: 100%;
      @include for-phone {
        font-size: 14px; } }
    .toltipe {
      white-space: nowrap;
      margin-right: 5px;
      font-size: 16px;
      text-align: right;
      color: #979797;
      background: #d5d5d5;
      cursor: pointer;
      @include for-phone {
        display: none; } }
    .icon {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      position: relative;
      @include for-phone {
        width: 29px;
        height: 29px; }
      svg {
        width: 31px;
        height: 31px;
        @include for-phone {
          width: 19px;
          height: 19px; } }
      &.empty {
        background: transparent;
        cursor: default; } }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: transparent; } }

  &-end {
    .element {
      background: #fff;
      .toltipe {
        background: #fff !important; } } }

  &-now {
    .element {
      background: #FF6700;
      .name,
      .info {
        color: #fff; }

      .toltipe {
        background: #FF6700!important; } } }
  &-red {
    .element {
      background: rgba(#FF0000, .1);
      border: 0 none;
      &:before {
        background: #ff0000; }
      .toltipe {
        background: #ffe8e8!important; } } }

  &-blue {
    .element {
      background: rgba(#5222D0, .1);
      border: 0 none;
      &:before {
        background: #5222D0; }
      .toltipe {
        background: #efebfb!important; } } }
  &-green {
    .element {
      background: rgba(#109FAF, .1);
      border: 0 none;
      &:before {
        background: #109FAF; }
      .toltipe {
        background: #eaf5f7 !important; } } } }

.alt-list_el {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
  &.show {
    display: flex; }
  &.hide {
    display: none; }
  &:last-of-type {
    .plane {
      height: 0; } }

  @include for-phone {
    flex-wrap: wrap; }
  &:last-child {
    margin-bottom: 0; }
  .left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 400px;
    flex-shrink: 0;
    @include for-phone {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 7px; } }
  .time {
    width: 110px;
    font-size: 18px;
    color: #ccc;
    flex-shrink: 0;
    margin-right: 27px;
    white-space: nowrap;
    @include for-phone {
      font-size: 13px;
      line-height: 14px; } }
  .speakers {
    width: 110px;
    font-size: 14px;
    color: #767676;
    @include for-phone {
      width: 100%;
      white-space: pre-line; } }
  .alt-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 23px 15px;
    overflow: hidden;
    position: relative;
    @include for-phone {
      padding: 8px 13px; }
    .name {
      font-size: 18px;
      color: #000;
      width: 100%;
      @include for-phone {
        font-size: 14px; } }
    .toltipe {
      white-space: nowrap;
      margin-right: 5px;
      font-size: 16px;
      text-align: right;
      color: #979797;
      background: #d5d5d5;
      cursor: pointer;
      @include for-phone {
        display: none; } }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: transparent; } }
  .point {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 3px;
    background-color: #fff;
    @include for-phone {
      margin-left: calc(97% - 6px); }
    .icon {
      width: 10px;
      height: 10px;
      background-color: #cc0001;
      border-radius: 50%;
      @include for-phone {
        width: 10px;
        height: 10px; }
      &.empty {
        background-color: #fff;
        border: 1px solid #ccc; }
      &.wait {
        background-color: #00FF00; }
      &.saveEvent {
        background-color: #fff;
        border: 1px solid #ccc; } } }
  &-end {
    .element {}
    background: #fff;
    .toltipe {
      background: #fff !important; } }

  &-now {
    .element {}
    background: #FF6700;
    .name,
    .info {
      color: #fff; }

    .toltipe {
      background: #FF6700!important; } }
  &-red {
    .element {}
    background: rgba(#FF0000, .1);
    border: 0 none;
    &:before {
      background: #ff0000; }
    .toltipe {
      background: #ffe8e8!important; } }

  &-blue {
    .element {}
    background: rgba(#5222D0, .1);
    border: 0 none;
    &:before {
      background: #5222D0; }
    .toltipe {
      background: #efebfb!important; } }
  &-green {
    .element {}
    background: rgba(#109FAF, .1);
    border: 0 none;
    &:before {
      background: #109FAF; }
    .toltipe {
      background: #eaf5f7 !important; } } }
.alt-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  &__speaker {
    margin: 5px 0;
    font-size: 14px;
    line-height: 16px;
    color: #ccc; } }
.plane {
  width: 2px;
  height: calc(100% + 20px);
  left: 407px;
  background-color: #ccc;
  position: absolute;
  z-index: 1;
  @include for-phone {
    left: 97%;
    top: 21px; } }
