.programs {
  padding-top: 65px;
  padding-bottom: 33px;
  width: 100%;
  &__head {
    font-size: 28px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 48px;
    text-align: center;
    @include for-phone {
      font-size: 24px;
      line-height: 28px;
      text-align: center; } }
  &__roles {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @include for-phone {
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .role__el {
      width: 25%;
      margin-right: 13px;
      height: 140px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 26px 20px;
      background: #F3F3F3;
      border-radius: 12px;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
      @include for-phone {
        width: 100%;
        margin-right: 0;
        margin-bottom: 18px; }
      &.active {
        color: #ffffff;
        background: #FF6700; }
      &:last-child {
        margin-right: 0;
        @include for-phone {
          margin-bottom: 0; } } } }
  &__subhead {
    margin-top: 54px;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
    @include for-phone {
      font-size: 20px;
      line-height: 30px; }
    &-gray {
      margin-top: 20px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(#000, .5);
      @include for-phone {
        margin-top: 8px;
        font-size: 14px;
        line-height: 21px; } } }

  @import "list";
  @import "list-el";

  &.programmVmeste {
    background: url("../../../image/vmeste/programBg.png");
    background-position: top center;
    background-repeat: repeat-y;
    background-size: contain;
    .programs__list {
      padding: 53px 65px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 7px rgba(87, 87, 87, 0.05);
      border-radius: 30px;
      @include for-phone {
        padding: 15px 20px;
        border-radius: 15px; } }
    .programs__list-track {
      display: none; }
    .element {
      a {
        pointer-events: none;
        cursor: default; }
      .icon {
        display: none; } } } }

.alt-programs {
  padding-top: 65px;
  padding-bottom: 33px;
  width: 100%;
  &__head {
    font-size: 32px;
    line-height: 40px;
    padding: 20px;
    color: #FFF;
    margin-bottom: 48px;
    background-color: #cc0001;
    text-transform: uppercase;
    text-align: center;
    @include for-phone {
      font-size: 24px;
      line-height: 28px;
      text-align: center; } } }
