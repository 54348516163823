.speaker {
  padding-top: 38px;
  width: 100%;
  background: url("../../../image/spikers_bg.svg") center top no-repeat;
  @include for-phone {
    padding-top: 75px; }
  .cnt {
    overflow: visible; }
  &__head {
    font-size: 48px;
    line-height: 56px;
    color: #000000;
    margin-bottom: 23px;
    @include for-phone {
      font-size: 24px;
      line-height: 1;
      text-align: center; } }
  &__flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include for-phone {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  &__el {
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: calc((100% / 3) - ( 68px / 3 * 2));
    margin-bottom: 38px;
    margin-right: 68px;
    padding: 0 20px;
    @include for-phone {
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 22px;
      align-items: center;
      padding: 0px; }
    &:nth-child(3n) {
      margin-right: 0; }
    .img {
      height: 138px;
      width: 138px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top; } }
    .name {
      margin-top: 20px;
      font-size: 22px;
      line-height: 24px;
      color: #FFF;
      @include for-phone {
        text-align: center;
        font-size: 18px;
        line-height: 30px; } }
    .text {
      margin-top: 20px;
      font-size: 16px;
      line-height: 18px;
      color: #FFF;
      @include for-phone {
        font-size: 14px;
        text-align: center; } } }


  &.vmesteSpeaker {
    .block svg {
      width: 150px;
      height: 126px;
      stroke-width: 1px;
      stroke: #000; }
    .speaker__el {
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 50%;
      padding-bottom: 60px;
      .img {
        flex-shrink: 0; } } } }

.alt-speaker {
  padding-top: 30px;
  width: 100%;
  background: url("../../../image/spikers_bg.svg") center top no-repeat;
  background-size: cover;
  @include for-phone {
    padding-top: 75px; }
  .cnt {
    overflow: visible; }
  &__head {
    font-size: 28px;
    line-height: 32px;
    padding: 20px;
    color: #FFF;
    margin-bottom: 48px;
    //background-color: #cc0001
    //text-transform: uppercase
    text-align: center;
    @include for-phone {
      font-size: 24px;
      line-height: 1;
      text-align: center; } }
  &__flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include for-phone {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  &__el {
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: calc((100% / 3) - ( 68px / 3 * 2));
    margin-bottom: 38px;
    margin-right: 68px;
    padding: 0 20px;
    @include for-phone {
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 22px;
      align-items: center;
      padding: 0px; }
    &:nth-child(3n) {
      margin-right: 0; }
    .img {
      height: 138px;
      width: 138px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top; } }
    .name {
      margin-top: 20px;
      font-size: 24px;
      line-height: 26px;
      color: #FFF;
      @include for-phone {
        text-align: center;
        font-size: 18px;
        line-height: 30px; } }
    .text {
      margin-top: 20px;
      font-size: 16px;
      line-height: 18px;
      color: #FFF;
      @include for-phone {
        font-size: 14px;
        text-align: center; } } }


  &.vmesteSpeaker {
    .block svg {
      width: 150px;
      height: 126px;
      stroke-width: 1px;
      stroke: #000; }
    .speaker__el {
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 50%;
      padding-bottom: 60px;
      .img {
        flex-shrink: 0; } } } }

