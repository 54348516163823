.header {
  position: relative;
  z-index: 3;
  width: 100%;
  padding: 10px 0;
  .cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
    overflow: visible; }
  &__ed {
    //background: linear-gradient(180deg, #CC0001 0%, #820707 100%)
    background: #FFF;
    //box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.24)
    .cnt {
      height: 80px; }
    .cnt2 {
      height: 100%; }
    .header__logo {
      margin-bottom: 0;
      margin-right: 48px;
      img {
        @include for-1000 {
          width: 140px; } } }
    .header__account-link {
      flex-direction: row-reverse;
      svg {
        margin-left: 0;
 } } }        //margin-right: 20px
  &__yellow {
    background: #FFD93A; }
  &__orange {
    background: #FF6700; }
  &__red {
    background: #D53827; }
  &__logo {
    margin-right: 28px;
    margin-bottom: -65px;
    flex-shrink: 0;
    @include for-1000 {
      order: 1;
      z-index: 3;
      margin-bottom: -25px; }
    img {
      @include for-1000 {
        width: 60px;
        height: auto; } } }
  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include for-1000 {
      order: 2;
      justify-content: flex-end; } }
  &__navigation {
    width: 100%;
    @include for-1000 {
      order: 3;
      width: auto; }
    &-burger {
      display: none;
      cursor: pointer;
      @include for-1000 {
        display: block; }
      & svg * {
        fill: #cc0001; } }
    .navigation {
      &__list {
        display: none;
        //justify-content: flex-start
        justify-content: space-around;
        align-items: center;
        width: 90%;
        &.show {
          display: flex;
          @include for-1000 {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 0;
            transform: translate(0, 100%) scaleY(0);
            width: 100vw;
            height: auto;
            padding: 20px;
            flex-direction: column;
            justify-content: center;
            background: #FFF;
            //background: linear-gradient(180deg, #820707 0%, #CC0001 100%)
            transform-origin: top center;
            animation: .5s ease .1ms forwards showMenu;
            @keyframes showMenu {
              from {
                transform: translate(0, 100%) scaleY(0); }

              to {
                transform: translate(0, 100%) scaleY(1); } } } }
        &-el {
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: #000;
          text-decoration: none;
          margin-right: 43px;
          border-bottom: 1px solid rgba(#000, 0);
          cursor: pointer;
          //background-image: linear-gradient(#000, #000)
          //background-position: 0 100%
          //background-repeat: no-repeat
          //background-size: 0 2px
          //transition: background-size .3s
          @include for-1000 {
            text-align: center;
            margin-right: 0;
            margin-bottom: 15px; }
          &:hover {
            border-bottom: 1px solid rgba(#000, 1);
 }            //background-size: 100% 2px
          &:last-child {
            margin-right: 0;
            margin-bottom: 0; } } } } }
  &__account {
    flex-shrink: 0;
    @include for-1000 {
      order: 2; }
    &-link {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      svg {
        //margin-left: 28px
        @include for-1000 {
          margin-left: 0;
 } } }          //margin-right: 18px
    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #000;
      text-decoration: none;
      white-space: pre-line;
      @include for-1000 {
        display: none; } }
    &-logo {
      height: 45px;
      width: 60px;
      display: flex;
      @include for-1000 {
        height: 25px;
        width: 35px; } } }
  &__tagline {
    color: #fff;
    text-transform: uppercase;
    text-align: center; }
  &__avatar {
    height: 100%;
    border-radius: 50%; } }
.patriot {
  height: 50px;
  object-fit: cover;
  @include for-phone {
    height: 80px; } }
