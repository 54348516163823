.forumDatEvents__page {
  .banner {
 }    //min-height: 735px
  .banner__bg {
    object-position: top left;
    object-fit: cover; }
  .banner__head {
    color: #000000; }
  .banner__text {
    color: #000000;
    opacity: 0.5; }

  .projects__all {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    .partner_el {
      width: calc(33% - (20px / 3 * 2));
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      display: none;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: flex; }
      &:nth-child(3n) {
        margin-right: 0; }
      @include for-phone {
        width: 100%; } }
    .partner_el-icon {
      width: 100%;
      height: 324px;
      border-radius: 20px; }
    .partner_el-img {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      img {
        position: static;
        transform: translate(0);
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
        object-position: center; } }


    &.show {
      .partner_el {
        display: flex; } }
    .partners__btns {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 42px;
      margin-bottom: 50px;
      @include for-phone {
        padding: 0 20px; } } }

  .expectations {
    position: relative;
    width: 100%;
    margin: 80px 0;
    padding-bottom: 110px;
    @include for-phone {
      margin-bottom: 40px; }
    &__title {
      margin-bottom: 34px;
      font-weight: bold;
      font-size: 28px;
      line-height: 38px;
      text-transform: uppercase;
      color: #CB0001;
      @include for-1000 {
        font-size: 20px;
        line-height: 30px; }
      @include for-phone {
        font-size: 18px;
        line-height: 26px; } }
    &__list {
      list-style: none;
      max-width: 866px;
      margin: 0 0 44px;
      padding: 0; }
    &__item {
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 27px;
      color: #303030;
      @include for-1000 {
        font-size: 16px;
        line-height: 26px; }
      @include for-phone {
        font-size: 14px;
        line-height: 18px; }
      &:not(:last-child) {
        margin-bottom: 20px; } }
    &__number {
      flex-shrink: 0;
      width: 37px;
      margin-right: 32px;
      font-weight: bold;
      font-size: 60px;
      line-height: 81px;
      text-transform: uppercase;
      color: #CB0001; }
    &__text {
      font-size: 20px;
      line-height: 27px;
      color: #303030;
      @include for-1000 {
        font-size: 16px;
        line-height: 26px; }
      @include for-phone {
        font-size: 14px;
        line-height: 18px; } }
    &__wave {
      position: absolute;
      right: 0;
      bottom: 0;
      @include for-1000 {
        width: 180px; }
      @include for-1000 {
        width: 130px; } } } }

