.map {
  position: relative;
  width: 100%;
  margin: 80px 0;
  padding-bottom: 110px;
  @include for-phone {
    margin-bottom: 40px; }
  &__container {
    width: 100%;
    position: relative;
    display: flex; }
  &__city {
    width: 65px;
    height: 65px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 18px;
    @include for-phone {
      width: 10px;
      height: 10px;
      font-size: 12px;
      line-height: 14px; }
    &.kaluga {
      top: 38.5%;
      left: 8.5%; }
    &.volgograd {
      top: 56%;
      left: 9%; }
    &.samara {
      top: 56%;
      left: 18%; }
    &.barnaul {
      top: 76%;
      left: 37.5%; } }
  &__icon {
    stroke: red;
    &>path {
      fill: #ccc; } }
  &__date {
    position: absolute;
    background-color: rgba(245, 245, 245, .8);
    color: #000;
    padding: 20px 40px;
    box-shadow: 0 0 0 0 #ccc, 0 -4px 10px 0 #ccc, 0 0 0 0 #ccc, 0 4px 10px 0 #ccc, 0 0 0 0 #ccc;
    opacity: 0;
    text-align: center;
    @include for-phone {
      transform: scale(.4) translate(-20%, 100%); }
    &.kaluga {
      top: calc(38.5% - 96px);
      left: calc(8.5% - 80px); }
    &.volgograd {
      top: calc(56% - 96px);
      left: calc(9% - 80px); }
    &.samara {
      top: calc(56% - 96px);
      left: calc(18% - 80px); }
    &.barnaul {
      top: calc(76% - 96px);
      left: calc(37.5% - 80px); }
    &.kaluga, &.volgograd, &.samara, &.barnaul {
      animation: show .5s ease-in-out 1 forwards;
      z-index: 10; } } }
.m {
  width: 350px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  @include for-phone {
    width: 150px; } }
@keyframes show {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }
