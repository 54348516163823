.forumsEl {
  padding: 0 10px;
  &__img {
    width: 100%;
    height: 324px;
    @include for-phone {
      height: 208px;
      border-radius: 10px;
      overflow: hidden; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__date {
    font-size: 18px;
    line-height: 21px;
    color: #FF6700;
    margin-top: 32px;
    @include for-phone {
      margin-top: 20px; } }
  &__head {
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-top: 16px;
    @include for-phone {
      font-size: 20px;
      line-height: 23px;
      margin-top: 6px; } }
  &__text {
    font-size: 18px;
    line-height: 21px;
    color: #979797;
    margin-top: 16px; }
  &__button {
    margin-top: 27px; } }
