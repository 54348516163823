.banner {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: -15%;
  @include for-phone {
    margin-bottom: -25%; }
  &.banner-ed {
    margin-bottom: 0;
    padding-top: 51.26%;
    background-color: #F2F2F2;
    @include for-phone {
      padding-top: 0;
      height: 400px; }
    .banner__bg {
      transform: translate(0, 0); } }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate(-17%, -4%);
    @include for-phone {
      transform: translate(-10%, -4%); } }
  &__bgPeople {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 1149px;
    height: 432px;
    object-fit: contain;
    object-position: bottom right;
    @include for-desktop {
      width: 800px; }
    @include for-tablet {
      width: 500px; }
    @include for-phone {
      width: 100%;
      object-fit: contain; } }

  &__date {
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #222222;
    margin-bottom: 19px;
    @include for-phone {
      font-size: 14px;
      line-height: 18px; } }

  &__head {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #FF6700;
    width: 55%;
    @include for-desktop-little {
      width: 60%; }
    @include for-phone {
      font-size: 24px;
      line-height: 28px;
      width: 100%; } }
  &__text {
    margin-top: 24px;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    width: 50%;
    @include for-phone {
      font-size: 14px;
      line-height: 18px;
      width: 100%; } }
  .button {
    margin-top: 30px; }


  &.bvmeste {
    color: #fff;
    @include for-phone {
      padding-top: 100px !important;
      padding-bottom: 0; }
    .banner__head {
      color: #fff;
      width: 50%;
      font-size: 64px;
      line-height: 72px;
      font-weight: normal;
      @include for-phone {
        width: 100%;
        font-size: 30px;
        line-height: 1.2; } }
    .banner__subHead {
      width: 50%;
      font-weight: bold;
      font-style: italic;
      font-size: 36px;
      line-height: 42px;
      margin-top: 6px;
      @include for-phone {
        width: 100%;
        font-size: 18px;
        line-height: 1.2; } }
    .banner__text2 {
      width: 50%;
      margin-top: 12px;
      font-size: 16px;
      line-height: 19px;
      @include for-phone {
        width: 100%;
        font-size: 14px;
        line-height: 1.2; } }
    .banner__text3 {
      width: 50%;
      margin-top: 25px;
      font-weight: bold;
      font-size: 22px;
      line-height: 25px;
      @include for-phone {
        width: 100%;
        font-size: 16px;
        line-height: 1.2; } }
    .banner__text4 {
      width: 50%;
      margin-top: 32px;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      @include for-phone {
        font-size: 18px;
        line-height: 1.2;
        width: 100%; } }
    .banner__foter {
      position: relative;
      z-index: 10;
      background-color: rgba(#FF6700, .5);
      margin-top: 30px;
      .cnt {
        padding-top: 32px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 36px;
        line-height: 42px;
        @include for-phone {
          font-size: 18px;
          line-height: 1.2;
          flex-direction: column;
          padding-top: 16px;
          padding-bottom: 10px; } } }

    .banner__btn {
      display: inline-block;
      font-size: 24px;
      line-height: 28px;
      padding: 16px 27px;
      background: #fff;
      color: #FF6700;
      border-radius: 13px;
      text-decoration: none;
      text-transform: uppercase;
      @include for-phone {
        font-size: 16px;
        line-height: 1.2;
        width: 100%;
        text-align: center;
        margin-top: 15px; } } }

  &__wave {
    position: absolute;
    bottom: 10px;
    @include for-1000 {
      width: 150px; }
    @include for-phone {
      width: 100px;
      bottom: 0; }
    &--right {
      right: 0; }
    &--left {
      left: 0; } }

  //Стили для текста шаблона education
  &__content {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 1220px;
    padding: 74px 20px 0;
    @include for-1000 {
      padding: 40px 20px 0; } }
  &__date-block {
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding: 10px 21px;
    background-color: #C10000;
    @include for-1000 {
      font-size: 18px;
      line-height: 20px; } }
  &__date-box {
    max-width: 610px;
    @include for-1000 {
      max-width: 500px;
      @include for-phone {
        max-width: 100%; } } }
  &__date-title {
    max-width: 500px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #C30000;
    text-transform: uppercase;
    @include for-1000 {
      font-size: 20px;
      line-height: 30px; }
    @include for-phone {
      font-size: 18px;
      line-height: 26px;
      max-width: 300px; } }
  &__date-subtitle {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #303030;
    @include for-1000 {
      font-size: 16px;
      line-height: 26px; }
    @include for-phone {
      font-size: 14px;
      line-height: 18px; } }
  &__date-text {
    max-width: 400px;
    font-size: 16px;
    line-height: 22px;
    color: #303030;
    @include for-1000 {
      font-size: 14px;
      line-height: 18px; }
    @include for-phone {
      font-size: 12px;
      line-height: 14px; } } }
