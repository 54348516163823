.training__page {
  background: #e6eff7; }
.content_head {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #FF6700; }
.studyPanels {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  padding-left: 0 !important;
  padding-right: 0 !important; }

.button__orange,
.button__white {
  padding: 10px 15px; }

