.searchInput {
  height: 60px;
  width: 100%;
  max-width: 395px;
  position: relative;
  @include for-phone {
    display: none; }
  input {
    border: 1px solid rgba(#000000, .2);
    box-sizing: border-box;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    padding: 20px 50px 20px 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
    &::placeholder {
      color: rgba(#000, .4); } }
  button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    border: 0 none;
    background: transparent;
    outline: 0 none;
    path {
      transition: .5s; }
    &:hover {
      path {
        opacity: 1;
        fill: #FF6700; } } } }


