.aboutMarathon {
  margin-top: 53px;
  width: 100%;
  @include for-phone {
    margin-top: 25px; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    @include for-phone {
      font-size: 32px; } }
  .marathons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4px;
    @include for-phone {
      flex-direction: column; }
    .Marathon__el {
      color: #F58E40;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 30px;
      @include for-phone {
        width: 100%;
        font-size: 16px;
        line-height: 1.2; }
      .icon {
        margin-right: 30px;
        @include for-phone {
          margin-right: 15px; }
        img {
          width: 57px;
          height: auto;
          @include for-phone {
            width: 30px; } } } } }
  &__btn {
    margin-top: 30px;
    display: inline-block;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    padding: 16px 27px;
    background: #FF6700;
    border-radius: 13px;
    @include for-phone {
      margin-top: 15px;
      font-size: 16px;
      line-height: 1.2;
      width: 100%; } } }


.howCountry {
  width: 100%;
  margin-top: 86px;
  @include for-phone {
    margin-top: 40px; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    @include for-phone {
      font-size: 32px; } }
  &__banner {
    margin-top: 34px;
    padding: 55px 26px 43px;
    background: linear-gradient(269.02deg, #FFD939 -13.28%, #F68F41 50.86%);
    border-radius: 7px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include for-phone {
      padding: 25px 15px 20px;
      flex-direction: column; } }

  &__icon {
    height: 94px;
    width: 94px;
    top: 20px;
    left: 30px;
    position: absolute;
    @include for-phone {
      display: none; } }
  .desc {
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    width: 50%;
    margin-right: 80px;
    @include for-phone {
      font-size: 16px;
      line-height: 1.2;
      width: 100%;
      margin-right: 0; }
    &__btn {
      margin-top: 65px;
      display: inline-block;
      font-size: 24px;
      line-height: 28px;
      color: #FF6700;
      padding: 16px 27px;
      background: #FFD939;
      border-radius: 13px;
      @include for-phone {
        margin-top: 30px;
        font-size: 16px;
        line-height: 1.2;
        width: 100%; } } }
  .list {
    width: 50%;
    margin-left: 80px;
    @include for-phone {
      width: 100%;
      margin-left: 0;
      margin-top: 20px; }
    &__head {
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      color: #fff;
      margin-bottom: 26px;
      @include for-phone {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 13px; } }
    &__el {
      position: relative;
      font-size: 24px;
      line-height: 28px;
      font-weight: normal;
      color: #fff;
      @include for-phone {
        font-size: 16px;
        line-height: 1.2;
        margin-left: 30px; }
      &:not(:last-child) {
        margin-bottom: 28px;
        @include for-phone {
          margin-bottom: 14px; } }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -40px;
        height: 22px;
        width: 22px;
        border-radius: 50px;
        background: #FFD939;
        @include for-phone {
          left: -30px;
          height: 15px;
          width: 15px; } } } } }


.awarding {
  width: 100%;
  margin-top: 49px;
  @include for-phone {
    margin-top: 25px; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    @include for-phone {
      font-size: 32px; } }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 37px;
    @include for-phone {
      margin-top: 20px;
      flex-direction: column; }
    .row__left {
      padding-right: 35px;
      @include for-phone {
        padding-right: 0; } }
    .row__right {
      padding-left: 35px;
      @include for-phone {
        padding-left: 0; } }
    .w100 {
      width: 100%;
      @include for-phone {
        margin-top: 20px; } }
    .fs {
      flex-shrink: 0;
      @include for-phone {
        margin-top: 20px; } }
    .row__el {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 24px;
      line-height: 28px;
      font-weight: normal;
      @include for-phone {
        font-size: 16px;
        line-height: 1.2; }
      &:not(:last-child) {
        margin-bottom: 30px; }
      .icon {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 46px;
        flex-shrink: 0;
        @include for-phone {
          width: 40px;
          margin-right: 15px;
          img {
            width: 100%; } } } }

    .orange {
      background: linear-gradient(269.02deg, #FFD939 -13.28%, #F68F41 50.86%);
      border-radius: 7px;
      padding: 26px 44px;
      color: #fff;
      font-size: 24px;
      line-height: 28px;
      font-weight: normal;
      max-width: 500px;
      @include for-phone {
        width: 100%;
        max-width: none;
        padding: 13px 22px;
        font-size: 16px;
        line-height: 1.2; } }
    .li {
      position: relative;
      font-size: 24px;
      line-height: 28px;
      @include for-phone {
        font-size: 16px;
        line-height: 1.2;
        margin-left: 40px; }
      &:not(:last-child) {
        margin-bottom: 30px;
        @include for-phone {
          margin-bottom: 15px; } }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -40px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #FFD939; } } } }

.photos {
  margin-top: 43px;
  width: 100%;
  padding-bottom: 90px;
  @include for-phone {
    margin-top: 20px;
    padding-bottom: 45px; }
  .cnt {
    overflow: visible; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    margin-bottom: 43px;
    @include for-phone {
      font-size: 32px;
      margin-bottom: 20px; } }
  img {
    height: 600px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    @include for-phone {
      height: 200px; } } }
.slick-track {
  display: flex;
  align-items: stretch; }
.slick-slide {
  height: auto;
  div:not([class]) {
    height: 100%; }
  .teamsSlider__column {
    height: 100%; } }


.slick-arrow {
  height: 56px;
  width: 56px;
  padding: 0;
  margin: 0;
  background: url("../../../assets/image/vmeste/arrow.png");
  top: 50%;
  &:hover,
  &:focus {
    background: url("../../../assets/image/vmeste/arrow.png"); }
  @include for-phone {
    display: none!important; }
  &:before {
    display: none; } }
.slick-prev {
  left: -70px; }
.slick-next {
  transform-origin: top;
  transform: rotate(180deg) translate(0, -50%);
  right: -70px; }
.org {
  width: 100%;
  margin-top: 86px;
  @include for-phone {
    margin-top: 40px; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    @include for-phone {
      font-size: 32px; } }
  .orgs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 50px;
    @include for-phone {
      flex-direction: column;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 15px; }
    .org__el {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background: #f3f3f3;
      transition: .5s;
      display: flex;
      justify-content: center;
      align-items: center;
      @include for-phone {
        margin-bottom: 15px; }
      &:hover {
        background: #FF6700; }
      img {
        width: 150px;
        height: auto;
        object-fit: contain; } } } }

.part {
  width: 100%;
  margin-top: 60px;
  @include for-phone {
    margin-top: 30px; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    @include for-phone {
      font-size: 32px; } }
  .parts {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @include for-phone {
      margin-top: 25px; }
    .part__el {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 30px 50px;
      @include for-phone {
        margin: 0 10px 25px; } } } }

.video {
  width: 100%;
  margin-top: 60px;
  @include for-phone {
    margin-top: 30px; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    @include for-phone {
      font-size: 32px; } }
  .videos {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include for-phone {
      margin-top: 25px; }
    iframe {
      margin-bottom: 40px; } }
  &__btn {
    margin-top: 30px;
    display: inline-block;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    padding: 16px 27px;
    background: #FF6700;
    border-radius: 13px;
    border: 0 none;
    box-shadow: none;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    cursor: pointer;
    @include for-phone {
      margin-top: 15px;
      font-size: 16px;
      line-height: 1.2;
      width: 100%; } } }


.action {
  margin-top: 30px;
  width: 100%;
  color: #F58E40;
  &__head {
    font-weight: normal;
    font-size: 48px;
    @include for-phone {
      font-size: 32px; } }
  &__text {
    margin: 20px 0;
    color: #fff;
    font-size: 36px;
    line-height: 42px;
    padding: 20px;
    background: linear-gradient(269.02deg, #FFD939 -13.28%, #F68F41 50.86%);
    border-radius: 7px;
    position: relative;
    @include for-phone {
      padding: 10px;
      font-size: 18px;
      line-height: 1.2; }
    span {
      display: block;
      width: calc(100% - 350px);
      @include for-phone {
        width: 100%; } }
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 429px;
      height: 242px;
      @include for-phone {
        display: none; } } }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @include for-phone {
      flex-direction: column; }
    &__el {
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 30%;
      @include for-phone {
        width: 100%;
        justify-content: flex-start; } }
    &__row {
      font-size: 30px;
      line-height: 39px;
      @include for-phone {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 15px; }
      &:not(:last-child) {
        margin-bottom: 30px;
        @include for-phone {
          margin-bottom: 15px; } } } }
  &__btn {
    margin-top: 30px;
    display: inline-block;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    padding: 16px 27px;
    background: #FF6700;
    border-radius: 13px;
    @include for-phone {
      margin-top: 15px;
      font-size: 16px;
      line-height: 1.2;
      width: 100%; } } }


.dobro {
  width: 100%;
  margin-top: 30px;
  &__text {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 20px;
    @include for-phone {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 15px; } }
  &__block {
    padding: 20px 30px;
    background-color: #F58E40;
    background-image: url("../../image/vmeste/dobro_bg.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    @include for-phone {
      padding: 10px 15px; } }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    @include for-phone {
      flex-direction: column; }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FF6700;
      border: 2px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 12px;
      color: #fff;
      font-size: 24px;
      padding: 10px 50px;
      width: 300px;
      margin-right: 20px;
      @include for-phone {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        margin-right: 0; } }
    span {
      font-size: 26px;
      line-height: 30px;
      color: #fff;
      @include for-phone {
        font-size: 18px;
        line-height: 1.2;
        text-align: center; } } }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #000;
    margin: 35px 0;
    @include for-phone {
      font-size: 32px; } }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    &__el {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 49%;
      margin-bottom: 30px;
      @include for-phone {
        width: 100%; }
      .icon {
        width: 122px;
        height: 122px;
        border-radius: 50%;
        background: #F68F41;
        margin-right: 11px;
        flex-shrink: 0;
        @include for-phone {
          width: 70px;
          height: 70px; } }
      span {
        font-size: 34px;
        line-height: 39px;
        color: #F68F41;
        @include for-phone {
          font-size: 14px;
          line-height: 1.2; } } } }
  &__btn {
    margin-top: 30px;
    display: inline-block;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    padding: 16px 27px;
    background: #FF6700;
    border-radius: 13px;
    @include for-phone {
      margin-top: 15px;
      font-size: 16px;
      line-height: 1.2;
      width: 100%; } } }


.broadcast {
  width: 100%;
  margin-top: 90px;
  position: relative;
  min-height: 1000px;
  &__bg {
    background: linear-gradient(45.01deg, #F58E40 53.87%, #FAD60F 96.59%);
    position: absolute;
    z-index: 1;
    width: 100%;
    min-height: 1000px;
    top: 0;
    left: 0;
    &:before {
      content: url("../../image/vmeste/trans-before.png");
      position: absolute;
      top: 0;
      left: 0; }
    &:after {
      content: url("../../image/vmeste/trans-after.png");
      position: absolute;
      bottom: 0;
      right: 0; } }
  .cnt {
    padding-top: 66px;
    position: relative;
    z-index: 2; }
  &__head {
    font-weight: normal;
    font-size: 48px;
    color: #fff;
    margin-bottom: 25px;
    @include for-phone {
      font-size: 32px; } }
  &__text {
    font-size: 24px;
    line-height: 30px;
    color: #fff; }
  &__video {
    margin-top: 33px;
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    iframe {
      width: calc(100% - 400px);
      height: 100%; } }
  &__program {
    width: 400px;
    height: 450px;
    background: #fff;
    .program__head {} } }

.other-arrows {
  .slick-arrow {
    height: 56px;
    width: 56px;
    padding: 0;
    margin: 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 330 330' fill='%23cc0001'%3E%3Cpath d='M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M225.606,175.605 l-80,80.002C142.678,258.535,138.839,260,135,260s-7.678-1.464-10.606-4.394c-5.858-5.857-5.858-15.355,0-21.213l69.393-69.396 l-69.393-69.392c-5.858-5.857-5.858-15.355,0-21.213c5.857-5.858,15.355-5.858,21.213,0l80,79.998 c2.814,2.813,4.394,6.628,4.394,10.606C230,168.976,228.42,172.792,225.606,175.605z'/%3E%3C/svg%3E")!important;
    top: 50%;
    &:hover,
    &:focus {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 330 330' fill='%23cc0001'%3E%3Cpath d='M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M225.606,175.605 l-80,80.002C142.678,258.535,138.839,260,135,260s-7.678-1.464-10.606-4.394c-5.858-5.857-5.858-15.355,0-21.213l69.393-69.396 l-69.393-69.392c-5.858-5.857-5.858-15.355,0-21.213c5.857-5.858,15.355-5.858,21.213,0l80,79.998 c2.814,2.813,4.394,6.628,4.394,10.606C230,168.976,228.42,172.792,225.606,175.605z'/%3E%3C/svg%3E")!important; }
    @include for-phone {
      display: none!important; }
    &:before {
      display: none; } } }
.slick-prev {
  left: -70px;
  transform-origin: top;
  transform: rotate(180deg) translate(0, -50%); }
.slick-next {
  transform-origin: top;
  transform: rotate(0deg) translate(0, -50%);
  right: -70px; }
